import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import PublicTemplate from '../components/PublicTemplate';
import WarningBanner from '../components/WarningBanner';
import Logo from '../components/Logo';

import { signUp } from 'state/slices/authSlice';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};

const validate = values => {
  const errors = {};

  if (!values.givenName) {
    errors.givenName = 'Required';
  }

  if (!values.familyName) {
    errors.familyName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};

export const SignUp = () => {
  const { message, status } = useSelector(state => state.status);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      givenName: '',
      familyName: '',
      email: '',
      password: '',
    },
    validate,
    onSubmit: values => {
      dispatch(signUp(values));
      history.push('/confirm-signup');
    },
  });

  return (
    <PublicTemplate>
      <div className="flex flex-col h-full">
        {status === 'rejected' && <WarningBanner text={message} />}
        <div className="flex-1 flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="flex h-full">
                  <div className="flex-1 flex flex-col justify-center">
                    <div className="mx-auto">
                      <Logo className="mx-auto h-12 w-auto" fill="#DC2626" alt="Fomoro Manufacturing Solutions Logo" />
                      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Sign up for a new account
                      </h2>
                      <p className="mt-2 text-center text-sm text-gray-600">
                        Or{' '}
                        <Link to="/" className="font-medium text-red-600 hover:text-red-500">
                          signin to your account
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* END RIGHT SIDE COL  */}
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form onSubmit={formik.handleSubmit}>
                  <input type="hidden" name="username" value={formik.values.email} />
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="givenName" className="block text-sm font-medium text-gray-700">
                            First name*
                          </label>
                          {formik.errors.givenName && formik.touched.givenName && (
                            <Error>{formik.errors.givenName}</Error>
                          )}
                          <input
                            type="text"
                            name="givenName"
                            id="givenName"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-700 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.givenName}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="familyName" className="block text-sm font-medium text-gray-700">
                            Last name*
                          </label>
                          {formik.errors.familyName && formik.touched.familyName && (
                            <Error>{formik.errors.familyName}</Error>
                          )}
                          <input
                            type="text"
                            name="familyName"
                            id="familyName"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-700 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.familyName}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email address*
                          </label>
                          {formik.errors.email && formik.touched.email && <Error>{formik.errors.email}</Error>}
                          <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="mt-1 focus:ring-indigo-700 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                        </div>

                        <div className="col-span-6">
                          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                          </label>
                          {formik.errors.password && formik.touched.password && <Error>{formik.errors.password}</Error>}
                          <input
                            type="password"
                            name="password"
                            id="password"
                            autoComplete="new-password"
                            className="mt-1 focus:ring-indigo-700 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        disabled={status === 'pending'}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicTemplate>
  );
};

export default SignUp;
