import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { updateJob } from 'state/slices/jobsSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';
import Tabs from '../components/Tabs';

import getJobSettingsNav from '../navs/jobSettingsNav';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

export const Tolerances = props => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { headerTitle, sectionNav, job, sectionTitle } = props;
  const { message, status, method } = useSelector(state => state.status);

  const { length, width, height, lengthTolerance, widthTolerance, heightTolerance } = job.targetMeasurements ?? {};

  const formik = useFormik({
    initialValues: {
      length: length ?? 100,
      width: width ?? 100,
      height: height ?? 100,
      lengthTolerance: lengthTolerance ?? 5,
      widthTolerance: widthTolerance ?? 5,
      heightTolerance: heightTolerance ?? 5,
    },
    onSubmit: targetMeasurements => {
      dispatch(
        updateJob({
          jobId,
          targetMeasurements,
        })
      );
    },
  });

  return (
    <PrivateTemplate sectionNav={sectionNav} sectionTitle={sectionTitle} headerTitle={`${headerTitle} › Size Settings`}>
      <Tabs nav={getJobSettingsNav(jobId, 'settings-sizes', job)} />
      {method === 'updateJob' && status === 'rejected' && <WarningBanner text={message} />}
      {method === 'updateJob' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${job.jobName}`} />
      )}

      <div className="border-t border-b border-gray-200  py-4 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <form onSubmit={formik.handleSubmit} className="">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                {formik.errors.length && formik.touched.length && <Error>{formik.errors.length}</Error>}
                <label htmlFor="length" className="block text-sm font-medium text-gray-700">
                  Length (mm)
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.length}
                    type="number"
                    step="0.01"
                    id="length"
                    min="10"
                    max="1000"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                {formik.errors.lengthTolerance && formik.touched.lengthTolerance && (
                  <Error>{formik.errors.lengthTolerance}</Error>
                )}
                <label htmlFor="lengthTolerance" className="block text-sm font-medium text-gray-700">
                  Tolerance (mm) &#177;
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.lengthTolerance}
                    type="number"
                    step="0.01"
                    id="lengthTolerance"
                    min="0"
                    max="100"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                {formik.errors.width && formik.touched.width && <Error>{formik.errors.width}</Error>}
                <label htmlFor="width" className="block text-sm font-medium text-gray-700">
                  Width (mm)
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.width}
                    type="number"
                    step="0.01"
                    id="width"
                    min="10"
                    max="1000"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                {formik.errors.widthTolerance && formik.touched.widthTolerance && (
                  <Error>{formik.errors.widthTolerance}</Error>
                )}
                <label htmlFor="widthTolerance" className="block text-sm font-medium text-gray-700">
                  Tolerance (mm) &#177;
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.widthTolerance}
                    type="number"
                    step="0.01"
                    id="widthTolerance"
                    min="0"
                    max="100"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                {formik.errors.height && formik.touched.height && <Error>{formik.errors.height}</Error>}
                <label htmlFor="height" className="block text-sm font-medium text-gray-700">
                  Height (mm)
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.height}
                    type="number"
                    step="0.01"
                    id="height"
                    min="10"
                    max="1000"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                {formik.errors.heightTolerance && formik.touched.heightTolerance && (
                  <Error>{formik.errors.heightTolerance}</Error>
                )}
                <label htmlFor="heightTolerance" className="block text-sm font-medium text-gray-700">
                  Tolerance (mm) &#177;
                </label>
                <div className="mt-1">
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.heightTolerance}
                    type="number"
                    step="0.01"
                    id="heightTolerance"
                    min="0"
                    max="100"
                    className="my-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="pt-8 flex justify-end">
              <button
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Tolerances;
