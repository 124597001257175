import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Tab } from '@headlessui/react';

import { XCircleIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

import { getProducts, getProduct, deleteProduct } from 'state/slices/productsSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import AttentionScores from '../components/AttentionScores';
import ImageZoom from '../components/ImageZoom';

import classNames from 'utils/classNames';
import linkItems from 'utils/linkItems';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const HeaderActions = ({ jobId, product, products, qualitySlug }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const total = Object.keys(products).length;
  const { previous, next, index, productId } = product;

  const handleDelete = (jobId, productId) => {
    dispatch(deleteProduct({ jobId, productId }));
    const url = next ? `/job/${jobId}/product/${next}/${qualitySlug}` : `/job/${jobId}/products`;
    history.push(url);
  };
  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex justify-between">
        <div className="sm:flex-1 sm:flex items-center justify-between">
          {index} of {total}
        </div>
        <nav aria-label="Pagination">
          <span className="ml-3 hidden md:inline-flex relative z-0 shadow-sm rounded-md">
            <Link
              to={`/job/${jobId}/product/${previous}/${qualitySlug}`}
              className={classNames(
                previous
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'relative inline-flex items-center xl:px-4 px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium'
              )}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>

            <Link
              to={`/job/${jobId}/product/${next}/${qualitySlug}`}
              className={classNames(
                next
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'text-sm font-medium -ml-px relative inline-flex items-center xl:px-4  px-2 py-2 rounded-r-md border border-gray-300'
              )}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
            <button type="submit" onClick={() => handleDelete(jobId, productId)}>
              <XCircleIcon
                className="ml-3 h-8 w-8 inline-flex align-middle text-gray-400 hover:text-red-600"
                aria-hidden="true"
              />
            </button>
          </span>
        </nav>
      </div>
    </div>
  );
};

export const Product = ({ job, sectionNav, sectionTitle, headerTitle }) => {
  const dispatch = useDispatch();
  const { jobId, paramA: productId, paramB: qualitySlug } = useParams();
  const quality = qualitySlug || 'recent';
  const next = useSelector(state => state.products.next[jobId]);

  const allProducts = Object.values(useSelector(state => state.products.items[jobId]) ?? {});
  const [products, setProducts] = useState({});

  const storedProduct = useSelector(state => state.products.items?.[jobId]?.[productId]) ?? {};
  const product = Object.assign({}, products[productId], storedProduct);

  const images = Object.entries(product.images ?? {});
  const resolvedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [query] = useState({
    quality: quality.toUpperCase(),
    sort: 'reverse',
  });

  useEffect(() => {
    const { quality } = query;

    const filteredProducts = ['GOOD', 'BAD', 'UNCERTAIN'].includes(quality)
      ? allProducts.filter(item => item.predictedQuality === quality)
      : allProducts;

    const linkedProducts = linkItems('productId', filteredProducts, true);

    setProducts(linkedProducts);
  }, [jobId, query, allProducts.length]); // eslint-disable-line

  useEffect(() => {
    if (next && allProducts.length >= 500) return;
    const nextQuery = next ? Object.assign({}, query, { next }) : query;
    dispatch(getProducts({ jobId, query: nextQuery }));
  }, [jobId, next, query]); // eslint-disable-line

  useEffect(() => {
    dispatch(getProduct({ jobId, productId: parseInt(productId, 10) }));
  }, [jobId, productId]); // eslint-disable-line

  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Products › ${product?.customerProductId ?? product?.productId}`}
      headerActions={<HeaderActions jobId={jobId} product={product} products={products} qualitySlug={qualitySlug} />}
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}>
      <Tab.Group as="div">
        <Tab.Panels className="w-full">
          {images.map(([cameraId, image]) => (
            <Tab.Panel key={cameraId}>
              <ImageZoom image={image['original']}>
                <AttentionScores
                  options={{
                    scores: product?.scores?.[cameraId],
                    key: `${cameraId}:${product?.scores?.[cameraId].length}`,
                    alpha: product?.scores?.[cameraId] ? 0.25 : 0,
                  }}
                  src={image['original']}
                />
              </ImageZoom>
            </Tab.Panel>
          ))}
        </Tab.Panels>
        <Tab.List className="sm:mb-4 mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 ml-4">
          {images.map(([cameraId, image]) => (
            <Tab key={cameraId} className="relative">
              {({ selected }) => (
                <span
                  className={classNames(
                    selected ? 'ring-gray-500 pointer-events-none' : 'ring-transparent',
                    'group block rounded-md ring-1 ring-offset-2 hover:ring-gray-400 border border-gray-50'
                  )}
                  aria-hidden="true">
                  <img src={image['thumbnail']} alt="" className="object-contain rounded-md pointer-events-none" />
                </span>
              )}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>

      {/* Product info */}
      <table className="col-span-1 min-w-full border-b border-gray-200 divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td className="px-4 py-4">
              <div className="text-sm">Quality</div>
            </td>
            <td className="px-4 py-4">
              <div className="text-sm capitalize">{product.predictedQuality}</div>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-4">
              <div className="text-sm">Identifier</div>
            </td>
            <td className="px-4 py-4">
              <div className="text-sm capitalize">{product.customerProductId ?? product.productId}</div>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-4 whitespace-nowrap">
              <div className="text-sm">Date</div>
            </td>
            <td className="px-4 py-4">
              <div className="text-sm">
                {dayjs(product.productId).tz(resolvedTimezone).format('h:mm:ss a, ddd MMM Do YYYY')} {resolvedTimezone}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </PrivateTemplate>
  );
};

export default Product;
