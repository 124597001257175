import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';

import { getDevice, updateDevice, updateComponentState } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import EmptyState from '../components/EmptyState';
import Tabs from '../components/Tabs';

import CameraSettings from './CameraSettings';

import getCameraNav from '../navs/cameraNav';

import classNames from 'utils/classNames';

export const Cameras = props => {
  const dispatch = useDispatch();
  const { deviceId, section, paramA: cameraIdSlug } = useParams();
  const { device, sectionNav, headerTitle, sectionTitle } = props;
  const [debounceTimeout, setDebounceTimeout] = useState({});
  const { message, status } = useSelector(state => state.status);

  const { cameraSettings: savedSettings, state } = device;
  const cameras = state?.reported?.cameras || {};

  const cameraIds = Object.keys(cameras);
  const cameraId = cameraIdSlug ?? cameraIds[0];

  const initialSettings = Object.fromEntries(
    cameraIds.map(cameraId => {
      const perCameraSavedSettings = savedSettings?.[cameraId];
      const gain = cameras[cameraId]?.['props']?.['Gain']?.['min'] ?? 10;
      const exposure = cameras[cameraId]?.['props']?.['ExposureTime']?.['min'] ?? 100;

      const perCameraSettings = Object.assign({ gain, exposure, enabled: true }, perCameraSavedSettings);
      return [cameraId, perCameraSettings];
    })
  );

  const [cameraSettings, setCameraSettings] = useState(initialSettings);

  const desiredMode = state?.desired?.mode;
  const [enableSetup, setEnableSetup] = useState(desiredMode === 'SETUP');

  useEffect(() => {
    dispatch(getDevice({ deviceId }));
  }, [deviceId]); // eslint-disable-line

  useEffect(() => {
    if (!enableSetup) return;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        dispatch(
          updateComponentState({
            deviceId,
            componentName: 'QualityControl',
            state: { cameraSettings },
          })
        );
      }, 300)
    );
  }, [cameraSettings, enableSetup]); // eslint-disable-line

  const confirmSettings = () => {
    dispatch(updateDevice({ deviceId, cameraSettings }));
  };

  const handleSettingsChange = (cameraId, key, value) => {
    setCameraSettings(settings => {
      const newSettings = Object.assign({}, settings);
      newSettings[cameraId] = Object.assign({}, settings[cameraId] || {}, { [key]: value });
      return newSettings;
    });
  };

  const toggleMode = bool => {
    setEnableSetup(bool);
    const mode = bool ? 'SETUP' : 'READY';
    dispatch(
      updateComponentState({
        deviceId,
        componentName: 'QualityControl',
        state: { mode },
      })
    );
  };

  const headerActions = (
    <Switch.Group as="div" className="flex items-center justify-between">
      <Switch.Label as="span" className="flex-grow flex flex-col" passive>
        <span className="mr-4 text-sm font-medium text-gray-900">Setup cameras</span>
      </Switch.Label>
      <Switch
        checked={enableSetup}
        onChange={() => toggleMode(!enableSetup)}
        className={classNames(
          enableSetup ? 'bg-indigo-800' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
        )}>
        <span
          aria-hidden="true"
          className={classNames(
            enableSetup ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );

  return (
    <PrivateTemplate
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}
      headerTitle={`${headerTitle} › Cameras`}
      headerActions={headerActions}>
      <Tabs nav={getCameraNav('station', deviceId, section, cameraIds, cameraId)} />

      {status === 'rejected' && <WarningBanner text={message} />}
      {!enableSetup ? (
        <EmptyState title="Not Enabled" description="Please enable setup mode your device." cta="Setup Cameras" />
      ) : (
        <div>
          <CameraSettings
            key={cameraId}
            cameraId={cameraId}
            camera={cameras[cameraId]}
            deviceId={deviceId}
            changeHandler={handleSettingsChange}
            cameraSettings={cameraSettings[cameraId]}
          />
          <div className="px-6 py-4 max-w-7xl w-full">
            <div className="flex justify-end">
              <button
                onClick={confirmSettings}
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default Cameras;
