import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import * as Plot from '@observablehq/plot';

import { selectComponentState, updateComponentState, getDeviceCalibration } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import Chart from '../components/Chart';

import classNames from 'utils/classNames';
import useInterval from 'utils/useInterval';

const parseData = data => {
  if (!data) return [];
  const bytes = Uint8Array.from(atob(data), c => c.charCodeAt(0));
  const dataview = new DataView(bytes.buffer);
  const values = [];
  for (let i = 0; i < dataview.byteLength; i += 4) {
    values.push(dataview.getFloat32(i, true));
  }
  const arr = [];
  while (values.length > 0) {
    arr.push(values.splice(0, 2));
  }
  return arr;
};

export const Calibration = props => {
  const dispatch = useDispatch();
  const { deviceId } = useParams();
  const { headerTitle, sectionNav, device, sectionTitle } = props;
  const { cameraIntrinsics, cameraIntrinsicsDetail, cameraExtrinsics } = device;

  const getSelectedState = useMemo(selectComponentState, []);
  const componentState = useSelector(state => getSelectedState(state, { deviceId, filter: 'QualityControl' }));

  const desiredMode = componentState?.desired?.mode;
  const [enableCalibration, setEnableCalibration] = useState(desiredMode === 'CALIBRATION');

  useEffect(() => {
    dispatch(getDeviceCalibration({ deviceId }));
  }, [deviceId]); // eslint-disable-line

  useInterval(() => {
    dispatch(getDeviceCalibration({ deviceId }));
  }, 10 * 1000);

  const toggleMode = bool => {
    setEnableCalibration(bool);
    const mode = bool ? 'CALIBRATION' : 'READY';
    dispatch(
      updateComponentState({
        deviceId,
        componentName: 'QualityControl',
        state: { mode },
      })
    );
  };

  const headerActions = (
    <Switch.Group as="div" className="flex items-center justify-between">
      <Switch.Label as="span" className="flex-grow flex flex-col" passive>
        <span className="mr-4 text-sm font-medium text-gray-900">Calibrate cameras</span>
      </Switch.Label>
      <Switch
        checked={enableCalibration}
        onChange={() => toggleMode(!enableCalibration)}
        className={classNames(
          enableCalibration ? 'bg-indigo-800' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
        )}>
        <span
          aria-hidden="true"
          className={classNames(
            enableCalibration ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );
  return (
    <PrivateTemplate
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}
      headerTitle={`${headerTitle} › Calibration`}
      headerActions={headerActions}>
      <div className="bg-white flex flex-col flex-1 max-h-screen">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            {cameraIntrinsics && cameraIntrinsicsDetail ? (
              <div className="relative h-full">
                <section className="sm:py-8" aria-labelledby="gallery-heading">
                  {Object.entries(cameraIntrinsicsDetail).map(([cameraId, perCameraIntrinsics]) => {
                    const poseErrors = cameraIntrinsics[cameraId]['poseErrors'];
                    const projectedPoints = parseData(perCameraIntrinsics.projectedPoints);
                    const residualPoints = parseData(perCameraIntrinsics.residualPoints);
                    const vectors = projectedPoints.map(([x, y], i) => {
                      const [u, v] = residualPoints[i];
                      const residualMagnitude = Math.hypot(u, v);
                      const residualAngle = (Math.atan2(v, u) * 180) / Math.PI;
                      const magnitude = Math.hypot(x, y);
                      const direction = (Math.atan2(y, x) * 180) / Math.PI + (magnitude / 1440) * 180;
                      return { x, y, u, v, direction, residualMagnitude, residualAngle };
                    });

                    const residualRange = residualPoints.reduce(
                      (range, [x, y]) => Math.max(range, Math.abs(x), Math.abs(y)),
                      0
                    );
                    const residualDomain = [-residualRange, +residualRange];
                    const poseErrorsMax = Math.max(...poseErrors);
                    return (
                      <div className="flex flex-col flex-1 xl:overflow-y-auto" key={cameraId}>
                        <div className="align-middle inline-block min-w-full">
                          <div className="flex overflow-hidden py-4 px-4">
                            <Chart
                              data={poseErrors}
                              options={{
                                x: {
                                  label: 'Error',
                                },
                                y: {
                                  grid: true,
                                  label: 'Error (px)',
                                  domain: [0, poseErrorsMax],
                                  nice: true,
                                },
                                marks: [
                                  Plot.frame({ fill: 'white', stroke: 'black' }),
                                  Plot.boxY(poseErrors, {
                                    fill: 'rgb(156, 163, 175)',
                                    insetLeft: 4,
                                    insetRight: 4,
                                  }),
                                ],
                                width: 108,
                                height: 351,
                                marginRight: 30,
                              }}
                            />
                            <Chart
                              data={poseErrors}
                              options={{
                                x: {
                                  label: 'Pose',
                                },
                                y: {
                                  label: 'Pose Errors (px)',
                                  grid: true,
                                  nice: true,
                                },
                                marks: [
                                  Plot.frame({ fill: 'white', stroke: 'black' }),
                                  Plot.barY(poseErrors, {
                                    x: (_, i) => i,
                                    y: d => d,
                                    fill: 'rgb(156, 163, 175)',
                                  }),
                                  Plot.ruleY([0]),
                                ],
                                width: 640,
                                height: 360,
                              }}
                            />
                            <Chart
                              data={vectors}
                              options={{
                                x: {
                                  label: 'Residual x (px) →',
                                  domain: residualDomain,
                                  nice: true,
                                },
                                y: {
                                  label: '↑ Residual y (px)',
                                  domain: residualDomain,
                                  nice: true,
                                },
                                color: {
                                  type: 'cyclical',
                                },
                                marks: [
                                  Plot.frame({ fill: 'white', stroke: 'black' }),
                                  Plot.dot(vectors, {
                                    x: v => v.u,
                                    y: v => v.v,
                                    r: 1.5,
                                    fill: 'direction',
                                    fillOpacity: 0.8,
                                    clip: true,
                                  }),
                                ],
                                width: 360,
                                height: 360,
                              }}
                            />
                            <Chart
                              data={vectors}
                              options={{
                                color: {
                                  type: 'linear',
                                },
                                length: {
                                  range: [0, 30],
                                },
                                x: {
                                  label: 'Point x (px) →',
                                },
                                y: {
                                  label: '↑ Point y (px)',
                                },
                                marks: [
                                  Plot.frame({ fill: 'white', stroke: 'black' }),
                                  Plot.vector(vectors, {
                                    x: 'x',
                                    y: 'y',
                                    rotate: 'residualAngle',
                                    length: 'residualMagnitude',
                                    stroke: 'residualMagnitude',
                                    anchor: 'start',
                                    clip: true,
                                    strokeWidth: 2.0,
                                  }),
                                ],
                                width: 480,
                                height: 360,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </section>
                <section>
                  <div className="border-t bg-gray-100 border-gray-200 flex flex-col flex-1 max-h-screen overflow-y-auto">
                    <div className="align-middle inline-block min-w-full">
                      <div className="border-b border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider"
                                colSpan="2"></th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider"
                                colSpan="2">
                                Focal Length
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider"
                                colSpan="2">
                                Principal Point
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider"
                                colSpan="3">
                                Radial Distortion
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider"
                                colSpan="2">
                                Tangential Distortion
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider">
                                Camera
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider">
                                Error
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                f<sub>x</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                f<sub>y</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                c<sub>x</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                c<sub>y</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                k<sub>1</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                k<sub>2</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                k<sub>3</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                p<sub>1</sub>
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-extrastrong text-gray-500 tracking-wider">
                                p<sub>2</sub>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {Object.entries(cameraIntrinsics).map(
                              ([cameraId, { error, matrix, distortion, stddevIntrinsics }]) => (
                                <tr key={cameraId}>
                                  <td className="px-4 py-2 whitespace-nowrap">{cameraId}</td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">{error.toPrecision(4)}px</div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {matrix[0][0].toPrecision(4)} &plusmn; {stddevIntrinsics.fx.toPrecision(4)}px
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {matrix[1][1].toPrecision(4)} &plusmn; {stddevIntrinsics.fy.toPrecision(4)}px
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {matrix[0][2].toPrecision(4)} &plusmn; {stddevIntrinsics.cx.toPrecision(4)}px
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {matrix[1][2].toPrecision(4)} &plusmn; {stddevIntrinsics.cy.toPrecision(4)}px
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {distortion[0][0].toPrecision(4)} &plusmn; {stddevIntrinsics.k1.toPrecision(4)}
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {distortion[0][1].toPrecision(4)} &plusmn; {stddevIntrinsics.k2.toPrecision(4)}
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {distortion[0][4].toPrecision(4)} &plusmn; {stddevIntrinsics.k3.toPrecision(4)}
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {distortion[0][2].toPrecision(4)} &plusmn; {stddevIntrinsics.p1.toPrecision(4)}
                                    </div>
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap">
                                    <div className="text-sm">
                                      {distortion[0][3].toPrecision(4)} &plusmn; {stddevIntrinsics.p2.toPrecision(4)}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                            {Object.entries(cameraExtrinsics).map(([cameraIdPair, { error }]) => (
                              <tr key={cameraIdPair}>
                                <td className="px-4 py-2 whitespace-nowrap">{cameraIdPair}</td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <div className="text-sm">{error.toPrecision(4)}px</div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <EmptyState
                title="Not Calibrated"
                description="Please enable calibration mode your device."
                cta="Calibrate Cameras"
              />
            )}
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Calibration;
