import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { createReport, testReport } from 'state/slices/reportsSlice';
import { getJobs, selectAllJobs } from 'state/slices/jobsSlice';
import { getUsers, selectAllUsers } from 'state/slices/usersSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import ButtonGroup from '../components/ButtonGroup';

import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';
import EmptyState from '../components/EmptyState';

const Error = props => {
  return <div className="text-red-500 font-extrabold text-xs">{props.children}</div>;
};

const validate = values => {
  const errors = {};

  if (values.jobIds.length === 0) {
    errors.jobIds = 'Required';
  }
  if (values.userIds.length === 0) {
    errors.userIds = 'Required';
  }
  if (values.cadence.length === 0) {
    errors.cadence = 'Required';
  }
  if (values.reportName.length === 0) {
    errors.reportName = 'Required';
  }
  return errors;
};

export const AddReport = props => {
  const dispatch = useDispatch();
  const { message, args, status, method } = useSelector(state => state.status);

  const getSelectedJobs = useMemo(selectAllJobs, []);
  const jobs = useSelector(state => getSelectedJobs(state));

  const getSelectedUsers = useMemo(selectAllUsers, []);
  const users = useSelector(state => getSelectedUsers(state));

  useEffect(() => {
    dispatch(getJobs());
    dispatch(getUsers());
  }, []); // eslint-disable-line

  const formik = useFormik({
    initialValues: {
      jobIds: [],
      userIds: [],
      reportName: '',
      cadence: [],
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      dispatch(createReport(values));
      resetForm();
    },
  });

  const handleJobChange = selectedId => {
    const jobIds = formik.values.jobIds;
    const selectedIndex = jobIds.indexOf(selectedId);

    let selected;
    if (selectedIndex === -1) {
      selected = [...jobIds, selectedId];
    } else {
      selected = jobIds.filter(id => id !== selectedId);
    }
    formik.setFieldValue('jobIds', selected);
  };

  const handleUserChange = selectedId => {
    const userIds = formik.values.userIds;
    const selectedIndex = userIds.indexOf(selectedId);

    let selected;
    if (selectedIndex === -1) {
      selected = [...userIds, selectedId];
    } else {
      selected = userIds.filter(id => id !== selectedId);
    }
    formik.setFieldValue('userIds', selected);
  };

  const handleCadenceChange = selected => {
    formik.setFieldValue('cadence', [selected]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const sendTest = () => {
    formik.setTouched(formik.values);
    dispatch(testReport(formik.values));
  };

  return (
    <PrivateTemplate headerTitle="Add Automated Report">
      {method === 'testReport' && status === 'fulfilled' && (
        <SuccessBanner text={`${args.reportName} test report sent`} />
      )}
      {method === 'testReport' && status === 'rejected' && <WarningBanner text={message} />}

      {method === 'createReport' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully created ${args.reportName}`} buttonText="See All" to={`/reports`} />
      )}
      {method === 'createReport' && status === 'rejected' && <WarningBanner text={message} />}

      {!jobs || jobs.length === 0 ? (
        <EmptyState
          title="No Jobs"
          description="All reports are attached to at least one job. You must have at a registered job to create a report."
          cta="Add Job"
          to="/jobs/add/"
        />
      ) : (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <form className="" onSubmit={handleSubmit}>
            <div>
              <div className="divide-y divide-gray-200">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-4 py-6">
                  <label htmlFor="reportName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Report Name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.reportName && formik.touched.reportName && <Error>{formik.errors.reportName}</Error>}
                    <input
                      id="reportName"
                      name="reportName"
                      type="text"
                      placeholder="Daily Report Lines ABC and XYZ"
                      onChange={formik.handleChange}
                      value={formik.values.reportName}
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:py-4 py-6 sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications">
                      Jobs
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    {formik.errors.jobIds && formik.touched.jobIds && <Error>{formik.errors.jobIds}</Error>}
                    <ButtonGroup items={jobs} checkedItems={formik.values.jobIds} changeHandler={handleJobChange} />
                  </div>
                </div>

                <div className="sm:py-4 py-6 sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications">
                      Type & Frequency
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    {formik.errors.cadence && formik.touched.cadence && <Error>{formik.errors.cadence}</Error>}
                    <ButtonGroup
                      items={[
                        { id: 'DAILY', name: 'Daily' },
                        { id: 'WEEKLY', name: 'Weekly' },
                        { id: 'MONTHLY', name: 'Monthly' },
                      ]}
                      checkedItems={formik.values.cadence}
                      changeHandler={handleCadenceChange}
                    />
                  </div>
                </div>
                <div className="sm:py-4 py-6 sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications">
                      Include
                    </div>
                  </div>
                </div>
                <div className="sm:py-4 py-6 sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications">
                      Send To
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    {formik.errors.userIds && formik.touched.userIds && <Error>{formik.errors.userIds}</Error>}
                    <ButtonGroup items={users} checkedItems={formik.values.userIds} changeHandler={handleUserChange} />
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:border-t sm:border-gray-200 pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={formik.handleReset}
                  disabled={status === 'pending'}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Reset
                </button>
                <button
                  type="button"
                  onClick={() => sendTest()}
                  disabled={status === 'pending'}
                  className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Test
                </button>
                <button
                  type="submit"
                  disabled={status === 'pending'}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Schedule
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default AddReport;
