import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import PrivateTemplate from '../components/PrivateTemplate';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const COLORS = ['#10B981', '#EF4444', '#F59E0B', '#3B82F6', '#6366F1', '#8B5CF6', '#EC4899', '#6B7280'];
const EXCLUDEHEADERS = ['day', 'labeled', 'hour', 'jobId', 'deviceId', 'camera1', 'camera2', 'camera3', 'camera4'];

const formatBarChartData = jobsData => {
  const dataset = Object.entries(jobsData).map(([key, data]) => {
    return Object.assign({ key }, data);
  });
  const labels = Object.keys(jobsData);
  return [
    {
      titleText: `Quality`,
      labels,
      datasets: [
        {
          label: 'Good',
          color: COLORS[0],
          borderColor: COLORS[0],
          backgroundColor: COLORS[0],
          data: dataset,
          parsing: {
            yAxisKey: 'good',
            xAxisKey: 'key',
          },
        },
        {
          label: 'Bad',
          color: COLORS[1],
          borderColor: COLORS[1],
          backgroundColor: COLORS[1],
          data: dataset,
          parsing: {
            yAxisKey: 'bad',
            xAxisKey: 'key',
          },
        },
        {
          label: 'Uncertain',
          color: COLORS[2],
          borderColor: COLORS[2],
          backgroundColor: COLORS[2],
          data: dataset,
          parsing: {
            yAxisKey: 'uncertain',
            xAxisKey: 'key',
          },
        },
      ],
    },
  ];
};

const formatLineChartData = (timeData, timeUnit) => {
  const resolvedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeFormat = timeUnit === 'hour' ? 'h:mm:ss a, ddd Do' : 'ddd MMM Do YYYY';
  const dataset = Object.entries(timeData)
    .map(([key, data]) => {
      const keyData = dayjs(parseInt(key, 10)).tz(resolvedTimezone).format(timeFormat);
      return Object.assign({ key: keyData }, data);
    })
    .reverse();
  const labels = Object.keys(timeData)
    .map(key => {
      return dayjs(parseInt(key, 10)).tz(resolvedTimezone).format(timeFormat);
    })
    .reverse();

  console.log('labels', labels);

  return [
    {
      titleText: `Quality`,
      labels,
      datasets: [
        {
          label: 'Good',
          color: COLORS[0],
          borderColor: COLORS[0],
          backgroundColor: COLORS[0],
          data: dataset,
          parsing: {
            yAxisKey: 'good',
            xAxisKey: 'key',
          },
        },
        {
          label: 'Bad',
          color: COLORS[1],
          borderColor: COLORS[1],
          backgroundColor: COLORS[1],
          data: dataset,
          parsing: {
            yAxisKey: 'bad',
            xAxisKey: 'key',
          },
        },
        {
          label: 'Uncertain',
          color: COLORS[2],
          borderColor: COLORS[2],
          backgroundColor: COLORS[2],
          data: dataset,
          parsing: {
            yAxisKey: 'uncertain',
            xAxisKey: 'key',
          },
        },
      ],
    },
  ];
};

export const ReportOverview = props => {
  const { headerTitle, sectionNav, report, sectionTitle } = props;
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    if (!report.jobs) return;

    const firstProductId = report.products[0]['productId'];
    const lastProductId = report.products[report.products.length - 1]['productId'];
    const timeUnit = firstProductId - lastProductId < 604800 ? 'hour' : 'day';

    const barData = formatBarChartData(report.jobs);
    const lineData = formatLineChartData(report.times, timeUnit);
    setBarChartData(barData);
    setLineChartData(lineData);
  }, [report?.jobs]); // eslint-disable-line

  return (
    <PrivateTemplate sectionNav={sectionNav} headerTitle={headerTitle} sectionTitle={sectionTitle}>
      {report.data && (
        <h3 className="py-4 px-4 flex border-b border-gray-200 relative z-10 sm:px-6 font-bold text-gray-900 text-2xl sm:truncate">
          Most Recent {report.data.length} Products
        </h3>
      )}
      <section className="py-4 px-8">
        {!report?.jobs ? (
          <div role="status" className="animate-pulse">
            <div className="mb-8 flex items-baseline mt-4 space-x-6">
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
            </div>
            <div className="mb-8 flex items-baseline mt-4 space-x-6">
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            {barChartData.map((datasets, index) => {
              return (
                <Bar
                  key={index}
                  options={{
                    responsive: true,
                    plugins: {
                      title: {
                        display: true,
                        text: datasets.titleText,
                        position: 'top',
                      },
                    },
                  }}
                  data={datasets}
                />
              );
            })}
            {lineChartData.map((datasets, index) => {
              return (
                <Line
                  key={index}
                  options={{
                    responsive: true,
                    plugins: {
                      title: {
                        display: true,
                        text: datasets.titleText,
                        position: 'top',
                      },
                    },
                  }}
                  data={datasets}
                />
              );
            })}
          </div>
        )}
      </section>
      <section>
        <div className="border-t bg-gray-100 border-gray-200 flex flex-col flex-1 max-h-screen overflow-y-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {report?.products &&
                      Object.keys(report['products'][0]).map(key => {
                        if (EXCLUDEHEADERS.includes(key)) {
                          return null;
                        }
                        return (
                          <th
                            key={key}
                            className="px-4 py-2 whitespace-nowrap text-left text-xs font-extrastrong text-gray-500 uppercase tracking-wider">
                            {key}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {report?.products &&
                    report?.products.map((product, index) => (
                      <tr key={`product-${index}`}>
                        {Object.entries(product).map(([key, value], index) => {
                          if (EXCLUDEHEADERS.includes(key)) {
                            return null;
                          }
                          if (['image1', 'image2', 'image3', 'image4'].includes(key)) {
                            const srcValue = `${value.slice(0, -4)}_thumbnail.webp`;
                            return (
                              <td key={`${product.productId}-${index}`} className="px-4 py-2 whitespace-nowrap">
                                <img src={srcValue} alt="" className="object-contain rounded-md pointer-events-none" />
                              </td>
                            );
                          }
                          return (
                            <td key={`${product.productId}-${index}`} className="px-4 py-2 whitespace-nowrap">
                              {value}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </PrivateTemplate>
  );
};

export default ReportOverview;
