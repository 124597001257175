import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useFormik } from 'formik';

import { createJob } from 'state/slices/jobsSlice';
import { getDevices, selectAllDevices } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import StatusDropDown from '../components/StatusDropDown';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';
import EmptyState from '../components/EmptyState';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

const validate = values => {
  const errors = {};
  if (!values.jobName) {
    errors.jobName = 'Required';
  }
  return errors;
};

export const AddJob = () => {
  const dispatch = useDispatch();
  const { deviceId } = useParams();

  const getSelectedDevices = useMemo(selectAllDevices, []);
  const devices = useSelector(state => getSelectedDevices(state));

  const [selectedDeviceId, setSelectedDeviceId] = useState(deviceId);

  const { message, status, payload, args, method } = useSelector(state => state.status);

  useEffect(() => {
    dispatch(getDevices());
  }, []); // eslint-disable-line

  const formik = useFormik({
    initialValues: {
      jobName: '',
      customerJobId: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const firstDeviceId = devices[0]['id'];
      dispatch(
        createJob({
          jobName: values.jobName,
          customerJobId: values.customerJobId,
          deviceId: selectedDeviceId || firstDeviceId,
        })
      );
      resetForm();
    },
  });

  const handleDeviceChange = device => {
    setSelectedDeviceId(device.id);
  };

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <PrivateTemplate headerTitle="Add Job">
      {method === 'createJob' && status === 'fulfilled' && (
        <SuccessBanner
          text={`Successfully created ${args.jobName}`}
          buttonText="Settings"
          to={`/job/${payload.jobId}/settings`}
        />
      )}
      {method === 'createJob' && status === 'rejected' && <WarningBanner text={message} />}
      {!devices || devices.length === 0 ? (
        <EmptyState
          title="No Stations"
          description="All jobs are attached to a specific station. You must have at least one registered station to create a job."
          cta="Add Station"
          to="/stations/add/"
        />
      ) : (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label htmlFor="device" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Station
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.deviceId && formik.touched.deviceId && <Error>{formik.errors.deviceId}</Error>}
                      <StatusDropDown
                        selectedId={selectedDeviceId}
                        changeHandler={handleDeviceChange}
                        items={devices}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="jobName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Job Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.jobName && formik.touched.jobName && <Error>{formik.errors.jobName}</Error>}
                      <input
                        id="jobName"
                        name="jobName"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.jobName}
                        placeholder="product name"
                        className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="customerJobId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Customer Job Id
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      {formik.errors.customerJobId && formik.touched.customerJobId && (
                        <Error>{formik.errors.customerJobId}</Error>
                      )}
                      <input
                        id="customerJobId"
                        name="customerJobId"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.customerJobId}
                        placeholder="product name"
                        className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Link
                  to="/jobs"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={status === 'pending'}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default AddJob;
