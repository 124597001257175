import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

const DEGREES_TO_RADIANS = Math.PI / 180;
const RADIANS_TO_DEGREES = 180 / Math.PI;
const EPSILON = 1e-6;

const getCanvasSize = (context, imageWidth, imageHeight) => {
  const canvas = context.canvas;

  const visualViewport = window.visualViewport;
  const parentEl = canvas.parentElement;
  const parentSize = parentEl.getBoundingClientRect();

  const parentWidth = parentSize.width;
  const parentHeight = visualViewport.height - parentSize.top;
  const parentAspectRatio = parentWidth / parentHeight;

  const imageAspectRatio = imageWidth / imageHeight;

  let canvasWidth, canvasHeight;
  if (imageAspectRatio < parentAspectRatio) {
    canvasWidth = (imageWidth * parentHeight) / imageHeight;
    canvasHeight = parentHeight;
  } else {
    canvasHeight = (imageHeight * parentWidth) / imageWidth;
    canvasWidth = parentWidth;
  }

  return [canvasWidth, canvasHeight];
};

const getProjectionMatrix = (cameraMatrix, w, h) => {
  const fx = cameraMatrix[0][0];
  const fy = cameraMatrix[1][1];
  const cx = cameraMatrix[0][2];
  const cy = cameraMatrix[1][2];
  const zn = 0.01;
  const zf = 10000;
  const A = (w - 2 * cx) / w;
  const B = (h - 2 * cy) / h;
  const C = (-zf - zn) / (zf - zn);
  const D = (-2 * zf * zn) / (zf - zn);
  return [(2 * fx) / w, 0, A, 0, 0, (2 * fy) / h, B, 0, 0, 0, C, D, 0, 0, -1, 0];
};

const createModelViewMatrix = extrinsics => {
  const extrinsicsMatrix = new THREE.Matrix4();

  if (extrinsics) {
    const { translation, rotation } = extrinsics;

    const translationVector = new THREE.Vector3();
    translationVector.set(translation[0][0], translation[1][0], translation[2][0]);

    const rotationMatrix = new THREE.Matrix3();
    rotationMatrix.set(
      rotation[0][0],
      rotation[0][1],
      rotation[0][2],
      rotation[1][0],
      rotation[1][1],
      rotation[1][2],
      rotation[2][0],
      rotation[2][1],
      rotation[2][2]
    );

    extrinsicsMatrix.setFromMatrix3(rotationMatrix);
    extrinsicsMatrix.setPosition(translationVector);
  }

  const correctionMatrix = new THREE.Matrix4();
  correctionMatrix.set(+1, +0, +0, +0, +0, -1, +0, +0, +0, +0, -1, +0, +0, +0, +0, +1);

  extrinsicsMatrix.multiply(correctionMatrix);
  return extrinsicsMatrix;
};

const lineLineIntersect = (p1, p2, p3, p4, pa, pb) => {
  const p13 = new THREE.Vector3();
  const p43 = new THREE.Vector3();
  const p21 = new THREE.Vector3();

  p13.subVectors(p1, p3);
  p43.subVectors(p4, p3);
  p21.subVectors(p2, p1);

  if (Math.abs(p43.x) < EPSILON && Math.abs(p43.y) < EPSILON && Math.abs(p43.z) < EPSILON) {
    return false;
  }

  if (Math.abs(p21.x) < EPSILON && Math.abs(p21.y) < EPSILON && Math.abs(p21.z) < EPSILON) {
    return false;
  }

  const d1343 = p13.x * p43.x + p13.y * p43.y + p13.z * p43.z;
  const d4321 = p43.x * p21.x + p43.y * p21.y + p43.z * p21.z;
  const d1321 = p13.x * p21.x + p13.y * p21.y + p13.z * p21.z;
  const d4343 = p43.x * p43.x + p43.y * p43.y + p43.z * p43.z;
  const d2121 = p21.x * p21.x + p21.y * p21.y + p21.z * p21.z;

  const numer = d1343 * d4321 - d1321 * d4343;
  const denom = d2121 * d4343 - d4321 * d4321;

  const mua = numer / denom;
  const mub = (d1343 + d4321 * mua) / d4343;

  if (Math.abs(denom) < EPSILON) {
    return false;
  }

  pa.addVectors(p1, p21.multiplyScalar(mua));
  pb.addVectors(p3, p43.multiplyScalar(mub));

  return true;
};

const getDefaultPosition = cameraExtrinsics => {
  const cameraMatrixA = createModelViewMatrix(cameraExtrinsics);
  const cameraMatrixB = createModelViewMatrix(null);

  const positionA = new THREE.Vector3();
  const positionB = new THREE.Vector3();

  positionA.set(cameraMatrixA.elements[12], cameraMatrixA.elements[13], cameraMatrixA.elements[14]);
  positionB.set(cameraMatrixB.elements[12], cameraMatrixB.elements[13], cameraMatrixB.elements[14]);

  const directionA = new THREE.Vector3(0, 0, 1);
  const directionB = new THREE.Vector3(0, 0, 1);

  directionA.set(-cameraMatrixA.elements[8], -cameraMatrixA.elements[9], -cameraMatrixA.elements[10]);
  directionB.set(-cameraMatrixB.elements[8], -cameraMatrixB.elements[9], -cameraMatrixB.elements[10]);

  directionA.normalize();
  directionB.normalize();

  directionA.multiplyScalar(2000);
  directionB.multiplyScalar(2000);

  directionA.add(positionA);
  directionB.add(positionB);

  const segmentA = new THREE.Vector3();
  const segmentB = new THREE.Vector3();
  const midpoint = new THREE.Vector3();

  const result = lineLineIntersect(positionA, directionA, positionB, directionB, segmentA, segmentB);
  if (result) {
    midpoint.addVectors(segmentA, segmentB).multiplyScalar(0.5);
  }
  return midpoint;
};

const useCanvas = (options, changeHandler) => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const controlRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const boxLinesRef = useRef(null);
  const parentElRef = useRef(null);

  const { imageWidth, imageHeight, measurements, cameraIntrinsics, cameraExtrinsics, cameraId } = options;
  const { length, width, height, x, y, z, roll, pitch, yaw } = measurements;

  const cameraMatrix = cameraIntrinsics?.[cameraId]?.['matrix'];
  const [cameraPair, cameraPairExtrinsics] = Object.entries(cameraExtrinsics)[0];

  const handleRender = () => {
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const camera = cameraRef.current;

    renderer.render(scene, camera);
  };

  const handleResize = () => {
    const renderer = rendererRef.current;
    const parentEl = parentElRef.current;
    const context = contextRef.current;
    const [canvasWidth, canvasHeight] = getCanvasSize(context, imageWidth, imageHeight);
    renderer.setSize(canvasWidth, canvasHeight);
    parentEl.style.height = `${canvasHeight}px`;
    handleRender();
  };

  const handleKeydown = e => {
    const control = controlRef.current;
    if (control === null) return;
    switch (e.keyCode) {
      case 81: // Q
        control.setSpace(control.space === 'local' ? 'world' : 'local');
        break;
      case 87: // W
        control.setMode('translate');
        break;
      case 69: // E
        control.setMode('rotate');
        break;
      case 82: // R
        control.setMode('scale');
        break;
      case 187:
      case 107: // +, =, num+
        control.setSize(control.size + 0.1);
        break;
      case 189:
      case 109: // -, _, num-
        control.setSize(Math.max(control.size - 0.1, 0.1));
        break;
      case 88: // X
        control.showX = !control.showX;
        break;
      case 89: // Y
        control.showY = !control.showY;
        break;
      case 90: // Z
        control.showZ = !control.showZ;
        break;
      case 32: // Spacebar
        control.enabled = !control.enabled;
        break;
      case 27: // Esc
        control.reset();
        break;
      default:
        break;
    }
  };

  const handleChange = () => {
    const boxLines = boxLinesRef.current;
    if (boxLines === null) return;

    changeHandler({
      length: boxLines.scale.x,
      width: boxLines.scale.y,
      height: boxLines.scale.z,
      x: boxLines.position.x,
      y: boxLines.position.y,
      z: boxLines.position.z,
      pitch: boxLines.rotation.x * RADIANS_TO_DEGREES,
      yaw: boxLines.rotation.y * RADIANS_TO_DEGREES,
      roll: boxLines.rotation.z * RADIANS_TO_DEGREES,
    });
    handleRender();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    contextRef.current = canvas.getContext('webgl2');

    const [canvasWidth, canvasHeight] = getCanvasSize(contextRef.current, imageWidth, imageHeight);
    parentElRef.current = canvas.parentElement;
    parentElRef.current.style.height = `${canvasHeight}px`;

    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.PerspectiveCamera();
    const camera = cameraRef.current;
    const scene = sceneRef.current;

    const projectionMatrix = getProjectionMatrix(cameraMatrix, imageWidth, imageHeight);
    camera.projectionMatrix.set(...projectionMatrix);
    camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();

    const extrinsicMatrix = cameraPair.split('_')[0] === cameraId ? cameraPairExtrinsics : null;
    const modelViewMatrix = createModelViewMatrix(extrinsicMatrix);
    camera.applyMatrix4(modelViewMatrix);
    scene.add(camera);

    const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
    const boxEdges = new THREE.EdgesGeometry(boxGeometry);

    boxLinesRef.current = new THREE.LineSegments(boxEdges);
    const boxLines = boxLinesRef.current;

    boxLines.material.depthTest = false;
    boxLines.material.opacity = 1;
    boxLines.material.transparent = true;
    boxLines.material.lineWidth = 2;
    scene.add(boxLines);

    rendererRef.current = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });
    const renderer = rendererRef.current;

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvasWidth, canvasHeight);

    controlRef.current = new TransformControls(camera, renderer.domElement);
    const control = controlRef.current;

    control.size = 0.5;
    control.space = 'local';
    control.attach(boxLines);
    scene.add(control);

    handleRender();

    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleKeydown);
    control.addEventListener('change', handleChange);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeydown);
      control.removeEventListener('change', handleChange);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const boxLines = boxLinesRef.current;
    if (boxLines === null) return;

    // check that we have a good rendering start position
    if (x === 0 && y === 0 && z === 0) {
      const defaultPosition = getDefaultPosition(cameraPairExtrinsics);
      measurements.x = defaultPosition.x;
      measurements.y = defaultPosition.y;
      measurements.z = defaultPosition.z;
    }

    const boxRotation = new THREE.Euler(
      pitch * DEGREES_TO_RADIANS,
      yaw * DEGREES_TO_RADIANS,
      roll * DEGREES_TO_RADIANS
    );

    boxLines.quaternion.setFromEuler(boxRotation);
    boxLines.position.set(x, y, z);
    boxLines.scale.set(length, width, height);

    handleRender();
  }, [length, width, height, x, y, z, roll, pitch, yaw]); // eslint-disable-line

  return canvasRef;
};

export const BoundingBox = ({ src, changeHandler, options }) => {
  const defaults = {
    imageWidth: 1440,
    imageHeight: 1080,
  };

  const canvasOptions = Object.assign(defaults, options);
  const canvasRef = useCanvas(canvasOptions, changeHandler);

  return (
    <div className="w-full bg-contain bg-no-repeat" style={{ backgroundImage: `url(${src})` }}>
      <canvas ref={canvasRef} width={canvasOptions.imageWidth} height={canvasOptions.imageHeight} tabIndex={-1} />
    </div>
  );
};

export default BoundingBox;
