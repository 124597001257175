import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { getJob, selectJob } from 'state/slices/jobsSlice';

import PrivateTemplate from '../components/PrivateTemplate';

import JobSettings from './JobSettings';
import Tolerances from './Tolerances';
import Labels from './Labels';
import Label from './Label';
import Models from './Models';
import Model from './Model';
import Measurements from './Measurements';
import Measurement from './Measurement';
import Products from './Products';
import Product from './Product';
import ROISetup from './ROISetup';
import Tags from './Tags';

import getJobNav from '../navs/jobNav';

export const Job = props => {
  const dispatch = useDispatch();
  const { jobId, section } = useParams();

  useEffect(() => {
    dispatch(getJob({ jobId }));
  }, [jobId]); // eslint-disable-line

  const getSelectedJob = useMemo(selectJob, []);
  const job = useSelector(state => getSelectedJob(state, { jobId }));

  const sectionNav = getJobNav(jobId, section, job);

  const jobCheck = Object.keys(job).length > 0;
  const view = jobCheck ? section : null;

  const deviceName = job?.device?.deviceName;
  const title = deviceName ? `${deviceName} › ${job.jobName}` : job.jobId;
  const sectionTitle = (
    <Link className="hover:text-indigo-900 hover:underline" to={`/jobs`}>
      ‹ All Jobs
    </Link>
  );

  switch (view) {
    case 'settings':
      return <JobSettings job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'settings-sizes':
      return <Tolerances job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'settings-labels':
      return <Tags job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'settings-rois':
      return <ROISetup job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'labels':
      return <Labels job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'label':
      return <Label job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'models':
      return <Models job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'model':
      return <Model job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'sizes':
      return <Measurements job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'size':
      return <Measurement job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'products':
      return <Products job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    case 'product':
      return <Product job={job} headerTitle={title} sectionNav={sectionNav} sectionTitle={sectionTitle} />;
    default:
      return <PrivateTemplate />;
  }
};

export default Job;
