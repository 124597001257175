import { useSelector, useDispatch } from 'react-redux';
import { QrCodeIcon } from '@heroicons/react/20/solid';
import { useFormik } from 'formik';

import { activateDeviceJob } from 'state/slices/devicesSlice';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};
const findMatchingJobs = (jobs, barcode) => {
  // return early if we find a matching jobID
  const jobKeys = Object.keys(jobs);
  const matchingJobId = jobKeys.find(jobId => barcode.includes(jobId));
  if (matchingJobId) return [jobs[matchingJobId]];

  // if no matching jobId, test customerJobId
  const jobValues = Object.values(jobs);
  return jobValues
    .filter(job => job.customerJobId && barcode.includes(job.customerJobId))
    .filter(job => {
      const jobId = job.customerJobId;
      const position = barcode.indexOf(jobId);
      const workOrder = position === 0 ? barcode.substring(jobId.length) : barcode.substring(0, position);
      return barcode.length === workOrder.length + jobId.length;
    });
};

export const BarcodeForm = () => {
  const dispatch = useDispatch();
  const jobs = useSelector(state => state.jobs.items) ?? {};

  const { status } = useSelector(state => state.status);

  const validate = values => {
    const errors = {};
    if (!values.barcode) {
      errors.barcode = 'Required';
      return errors;
    }

    const matches = findMatchingJobs(jobs, values.barcode);
    if (matches.length === 0) {
      errors.barcode = 'No matching customer job id';
      return errors;
    }
  };

  const formik = useFormik({
    initialValues: {
      barcode: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const { barcode } = values;
      const matchingJobs = findMatchingJobs(jobs, barcode);
      matchingJobs.forEach(job => {
        const { customerJobId, jobId, device, jobName } = job;
        const id = barcode.includes(jobId) ? jobId : customerJobId;
        const position = barcode.indexOf(id);
        const customerWorkOrder = position === 0 ? barcode.substring(id.length) : barcode.substring(0, position);
        dispatch(activateDeviceJob({ deviceId: device.deviceId, jobId, customerWorkOrder, mode: 'ACTIVE', jobName }));
      });
      resetForm();
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <label htmlFor="barcode" className="block text-sm font-medium leading-6 text-gray-900">
        Scan Barcode
      </label>
      {formik.errors.barcode && formik.touched.barcode && <Error>{formik.errors.barcode}</Error>}

      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            autoFocus
            type="text"
            name="barcode"
            id="barcode"
            onChange={formik.handleChange}
            value={formik.values.barcode}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <button
          type="submit"
          disabled={status === 'pending'}
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <QrCodeIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          Enter
        </button>
      </div>
    </form>
  );
};

export default BarcodeForm;
