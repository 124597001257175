import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Tab } from '@headlessui/react';

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

import { getProducts, getProduct, updateProduct } from 'state/slices/productsSlice';
import { getDeviceCalibration } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import BoundingBox from '../components/BoundingBox';
import EmptyState from '../components/EmptyState';

import classNames from 'utils/classNames';
import linkItems from 'utils/linkItems';
import capitalize from 'utils/capitalize';

const metricToImperial = metricValue => {
  return parseFloat((metricValue * 0.0393700787).toFixed(2));
};

const HeaderActions = ({ jobId, product, products, qualitySlug }) => {
  const total = Object.keys(products).length;
  const { previous, next, index } = product;
  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex justify-between">
        <div className="sm:flex-1 sm:flex items-center justify-between">
          {index} of {total}
        </div>
        <nav aria-label="Pagination">
          <span className="ml-3 hidden md:inline-flex relative z-0 shadow-sm rounded-md">
            <Link
              to={`/job/${jobId}/size/${previous}/${qualitySlug}`}
              className={classNames(
                previous
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'relative inline-flex items-center xl:px-4 px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium'
              )}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>

            <Link
              to={`/job/${jobId}/size/${next}/${qualitySlug}`}
              className={classNames(
                next
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'text-sm font-medium -ml-px relative inline-flex items-center xl:px-4  px-2 py-2 rounded-r-md border border-gray-300'
              )}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </span>
        </nav>
      </div>
    </div>
  );
};

export const Measurement = ({ job, sectionNav, sectionTitle, headerTitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { jobId, paramA: productId, paramB: qualitySlug } = useParams();

  const { status, method } = useSelector(state => state.status);
  const [nextUrl, setNextUrl] = useState(null);

  const next = useSelector(state => state.products.next[jobId]);

  const allProducts = Object.values(useSelector(state => state.products.items[jobId]) ?? {});

  const [products, setProducts] = useState({});
  const storedProduct = useSelector(state => state.products.items?.[jobId]?.[productId]) ?? {};
  const product = Object.assign({}, products[productId], storedProduct);

  const images = Object.entries(product.images ?? {});
  const device = job.device;

  const defaultMeasurements = {
    length: 0,
    width: 0,
    height: 0,
    roll: 0,
    pitch: 0,
    yaw: 0,
    x: 0,
    y: 0,
    z: 0,
  };
  // used to identify good vs bad measurements
  const targetMeasurements = Object.assign({}, defaultMeasurements, job?.targetMeasurements);

  // initialize measurements
  const initialMeasurements = Object.assign({}, targetMeasurements, product?.labeledMeasurements);
  const measurementCheckSum = Object.values(initialMeasurements).reduce((a, b) => a - b);
  const [measurements, setMeasurements] = useState(initialMeasurements);

  const [query] = useState({
    quality: qualitySlug.toUpperCase(),
    sort: 'reverse',
  });

  useEffect(() => {
    dispatch(getDeviceCalibration({ deviceId: device.deviceId }));
  }, [device.deviceId]); // eslint-disable-line

  useEffect(() => {
    const { quality } = query;
    const filteredProducts = ['GOOD', 'BAD'].includes(quality)
      ? allProducts.filter(item => item.labeledMeasurements && item.toleranceEvaluation === quality)
      : allProducts.filter(item => !item.labeledMeasurements);
    const linkedProducts = linkItems('productId', filteredProducts, true);
    setProducts(linkedProducts);
  }, [jobId, query, allProducts.length]); // eslint-disable-line

  useEffect(() => {
    if (next && allProducts.length >= 500) return;
    const query = next ? Object.assign({ sort: 'reverse' }, { next }) : { sort: 'reverse' };
    dispatch(getProducts({ jobId, query }));
  }, [jobId, next]); // eslint-disable-line

  useEffect(() => {
    dispatch(getProduct({ jobId, productId: parseInt(productId, 10) }));
  }, [jobId, productId]); // eslint-disable-line

  useEffect(() => {
    setMeasurements(initialMeasurements);
  }, [productId, measurementCheckSum]); // eslint-disable-line

  useEffect(() => {
    if (method !== 'updateProduct') return;
    if (status !== 'fulfilled') return;

    if (nextUrl) {
      const url = nextUrl;
      setNextUrl(null);
      history.push(url);
    }
  }, [method, status]); // eslint-disable-line

  const handleChange = changes => {
    setMeasurements(changes);
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      updateProduct({
        jobId,
        productId,
        labeledMeasurements: measurements,
      })
    );
    if (product.next) {
      setNextUrl(`/job/${jobId}/size/${product.next}/${qualitySlug}`);
    }
  };

  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Sizes › ${capitalize(product.toleranceEvaluation)}`}
      headerActions={<HeaderActions jobId={jobId} product={product} products={products} qualitySlug={qualitySlug} />}
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}>
      {!device?.cameraExtrinsics ? (
        <EmptyState
          title="Not Calibrated"
          description="Please enable calibration mode your device."
          cta="Calibrate Cameras"
        />
      ) : (
        <div className="lg:grid lg:grid-cols-12">
          <div className="lg:col-span-8 bg-white border-b border-gray-200 lg:border-transparent">
            <Tab.Group as="div">
              <Tab.Panels className="w-full">
                {images.map(([cameraId, image]) => (
                  <Tab.Panel key={cameraId}>
                    <BoundingBox
                      options={{
                        cameraId,
                        measurements,
                        cameraExtrinsics: device.cameraExtrinsics,
                        cameraIntrinsics: device.cameraIntrinsics,
                      }}
                      src={image['original']}
                      changeHandler={handleChange}
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
              <Tab.List className="sm:mb-4 mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 ml-4">
                {images.map(([cameraId, image]) => (
                  <Tab key={cameraId} className="relative">
                    {({ selected }) => (
                      <span
                        className={classNames(
                          selected ? 'ring-gray-500 pointer-events-none' : 'ring-transparent',
                          'group block rounded-md ring-1 ring-offset-2 hover:ring-gray-400 border border-gray-50'
                        )}
                        aria-hidden="true">
                        <img
                          src={image['thumbnail']}
                          alt=""
                          className="object-contain rounded-md pointer-events-none"
                        />
                      </span>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
          <aside className=" col-span-12 block lg:col-span-4 pb-4">
            <table className="w-full border-b border-gray-200 divide-y divide-gray-200">
              <tbody>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                    Length
                    <span
                      className={classNames(
                        measurements.length >= targetMeasurements.length - targetMeasurements.lengthTolerance &&
                          measurements.length <= targetMeasurements.length + targetMeasurements.lengthTolerance
                          ? 'text-green-500'
                          : 'text-red-500',
                        'pl-1 font-extrabold'
                      )}>
                      <br />({targetMeasurements.length.toFixed(2)}mm &#177; {targetMeasurements.lengthTolerance}mm)
                    </span>
                  </td>
                  <td
                    className={classNames(
                      measurements.length > 0 ? 'text-gray-700' : 'text-red-500',
                      'text-right px-4 py-4 whitespace-nowrap'
                    )}>
                    {measurements.length.toFixed(2)}mm
                    <br />
                    {metricToImperial(measurements.length)}in
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                    Width
                    <span
                      className={classNames(
                        measurements.width >= targetMeasurements.width - targetMeasurements.widthTolerance &&
                          measurements.width <= targetMeasurements.width + targetMeasurements.widthTolerance
                          ? 'text-green-500'
                          : 'text-red-500',
                        'pl-1 font-extrabold'
                      )}>
                      <br />({targetMeasurements.width.toFixed(2)}mm &#177; {targetMeasurements.widthTolerance}mm)
                    </span>
                  </td>
                  <td
                    className={classNames(
                      measurements.width > 0 ? 'text-gray-700' : 'text-red-500',
                      'text-right px-4 py-4 whitespace-nowrap'
                    )}>
                    {measurements.width.toFixed(2)}mm
                    <br />
                    {metricToImperial(measurements.width)}in
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                    Height
                    <span
                      className={classNames(
                        measurements.height >= targetMeasurements.height - targetMeasurements.heightTolerance &&
                          measurements.height <= targetMeasurements.height + targetMeasurements.heightTolerance
                          ? 'text-green-500'
                          : 'text-red-500',
                        'pl-1 font-extrabold'
                      )}>
                      <br />({targetMeasurements.height.toFixed(2)}mm &#177; {targetMeasurements.heightTolerance}mm)
                    </span>
                  </td>
                  <td
                    className={classNames(
                      measurements.height > 0 ? 'text-gray-700' : 'text-red-500',
                      'text-right px-4 py-4 whitespace-nowrap'
                    )}>
                    {measurements.height.toFixed(2)}mm
                    <br />
                    {metricToImperial(measurements.height)}in
                  </td>
                </tr>

                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">X</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.x.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Y</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.y.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Z</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.z.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Roll</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.roll.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Pitch</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.pitch.toFixed(2)}</td>
                </tr>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-700">Yaw</td>
                  <td className="text-right px-4 py-4 whitespace-nowrap">{measurements.yaw.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <div className="px-4 py-4">
              <div className="flex justify-end">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={status === 'pending'}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Save
                </button>
              </div>
            </div>
          </aside>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default Measurement;
