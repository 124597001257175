import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProducts } from 'state/slices/productsSlice';
import { getJob } from 'state/slices/jobsSlice';
import PrivateTemplate from '../components/PrivateTemplate';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const JOBID = 'a338f185-79f9-496a-b0ee-8f6cb498c692';

const buildReport = (jobArr, deviceArr, products) => {
  console.log('jobArr', jobArr);
  const jobs = jobArr.reduce((jobs, job) => {
    console.log('job', job);
    jobs[job.jobId] = job;
    return jobs;
  }, {});

  const reportDate = dayjs().format('ddd MMM Do');

  const groups = ['jobId', 'jobName', 'deviceId', 'deviceName', 'customerWorkOrder', 'customerJobId'];
  const metadata = {
    totalProducts: 0,
    quality: { GOOD: 0, BAD: 0, UNCERTAIN: 0 },
    length: { TOOBIG: 0, TOOSMALL: 0, GOOD: 0 },
    width: { TOOBIG: 0, TOOSMALL: 0, GOOD: 0 },
    height: { TOOBIG: 0, TOOSMALL: 0, GOOD: 0 },
    size: { TOOBIG: 0, TOOSMALL: 0, GOOD: 0 },
  };

  const reportData = Object.assign({}, metadata, {
    image: '',
    customerJobId: {},
    jobName: {},
    deviceId: {},
    customerDeviceId: {},
    customerWorkOrder: {},
    products: [],
  });

  // console.log('reportData', reportData);
  console.log('jobs', jobs);

  const report = products.reduce((report, product) => {
    const { jobId } = product.JobId ?? JOBID;
    const job = jobs[jobId];
    // const device = devices[job.deviceId];
    // console.log('job', job);

    //{JobId} =

    const quality = product?.LabeledQuality ?? product?.PredictedQuality;
    const measurements = product.PredictedMeasurements ?? product.LabeledMeasurements ?? {};
    const { length = 0, width = 0, height = 0 } = measurements;

    // groups.each(group => {
    //   // 'jobId',
    //   // 'jobName',
    //   // 'deviceId',
    //   // 'deviceName',
    //   // 'customerWorkOrder',
    //   // 'customerJobId',

    //   const groupName = product[group];
    //   const data = report[group][groupName] ?? Object.assign({}, metaData);
    //   data['quality'][quality]++;

    //   const {
    //     length: targetLength,
    //     width: targetWidth,
    //     height: targetHeight,
    //     lengthTolerance,
    //     widthTolerance,
    //     heightTolerance,
    //   } = job.TargetMeasurements;

    //   data['length'][quality]++;

    //   report[group][groupName] = data;
    // });

    return report;
  }, reportData);
  console.log('report', report);
};

export const TestPage = () => {
  const dispatch = useDispatch();
  //const jobId = '01526ee1-fb75-448e-a626-1f6bcfc4da18';
  const products = Object.values(useSelector(state => state.products.items[JOBID]) ?? {});
  const job = useSelector(state => state.jobs.items[JOBID]) ?? {};
  const jobs = [job];
  console.log('jobs', jobs);
  const devices = [job?.device];

  useEffect(() => {
    dispatch(getJob({ JOBID }));
    dispatch(getProducts({ JOBID, query: { limit: 50, sort: 'reverse' } }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (devices.length === 0) return;
    if (products.length === 0) return;
    buildReport(jobs, devices, products);
  }, [devices.length, products.length]); // eslint-disable-line

  return <PrivateTemplate headerTitle={'Admin › Test Page'}>Hello World!</PrivateTemplate>;
};

export default TestPage;
