import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { signOut } from 'state/slices/authSlice';

import { Dialog, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import {
  Bars4Icon,
  ServerIcon,
  BuildingOfficeIcon,
  ClipboardDocumentCheckIcon,
  ChartBarSquareIcon,
  LifebuoyIcon,
  XCircleIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';

import classNames from 'utils/classNames';

import Logo from '../components/Logo';
import SectionNav from '../components/SectionNav';

export const PrivateTemplate = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { headerTitle, headerActions, sectionTitle, sectionNav, secondaryNav } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useSelector(state => state.auth.user);

  const opsNavigation = [
    { name: 'Ops', to: '/ops', icon: ClipboardDocumentCheckIcon, current: location.pathname.startsWith('/op') },
    { name: 'Support', to: '/support', icon: LifebuoyIcon, current: location.pathname.startsWith('/support') },
  ];

  const defaultNavigation = [
    { name: 'Ops', to: '/ops', icon: ClipboardDocumentCheckIcon, current: location.pathname.startsWith('/op') },
    { name: 'Reports', to: '/reports', icon: ChartBarSquareIcon, current: location.pathname.startsWith('/report') },
    { name: 'Stations', to: '/stations', icon: ServerIcon, current: location.pathname.startsWith('/station') },
    { name: 'Jobs', to: '/jobs', icon: ClipboardDocumentCheckIcon, current: location.pathname.startsWith('/job') },
    { name: 'Account', to: '/account', icon: BuildingOfficeIcon, current: location.pathname.startsWith('/account') },
    { name: 'Support', to: '/support', icon: LifebuoyIcon, current: location.pathname.startsWith('/support') },
  ];

  const navigation = user['custom:accessGroup'].includes(['ops', 'fomoro-ops']) ? opsNavigation : defaultNavigation;

  const handleSignout = () => {
    dispatch(signOut());
    history.push('/');
  };

  return (
    <div className="sm:h-screen flex bg-gray-50 overflow-hidden">
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-12 pt-4">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setMobileMenuOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div>
                <Link
                  to="/"
                  className="text-lg pt-5 pb-4 hover:bg-gray-500 hover:text-white flex-shrink-0 flex items-center px-4">
                  Fomoro Manufacturing
                </Link>
                <nav aria-label="Sidebar">
                  <div>
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={classNames(
                          item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600  hover:bg-gray-200 hover:text-gray-900',
                          'group p-4 flex items-center text-base font-medium'
                        )}>
                        <item.icon
                          className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                    <button
                      onClick={handleSignout}
                      className="w-full group p-4 flex items-center text-base font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900">
                      <ArrowRightOnRectangleIcon
                        className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Signout
                    </button>
                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 ">
                <Link to="/account/user" className="p-4 w-full flex-shrink-0 group block hover:bg-gray-100">
                  <div className="flex items-center">
                    <div>
                      <UserCircleIcon className="text-red-700 inline-block h-10 w-10 rounded-full" />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900 ">
                        {user.givenName} {user.familyName}
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Account Settings</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-20">
          <div className="flex flex-col h-0 flex-1 overflow-y-auto bg-gray-900">
            <div className="flex-1 flex flex-col items-center">
              <Link
                to="/"
                className="bg-gray-900 text-gray-200 items-center w-16 py-2 my-2 rounded-lg hover:text-white hover:bg-gray-700">
                <Logo className="h-10 w-10 mx-auto" alt="Fomoro Manufacturing Solutions" />
              </Link>
              <nav aria-label="Sidebar" className="flex flex-col items-center space-y-3">
                {navigation.map(item => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={classNames(
                      item.current ? 'text-white bg-gray-700' : 'text-gray-200 hover:text-white hover:bg-gray-700',
                      'items-center w-16 py-4 rounded-lg '
                    )}>
                    <item.icon className="mx-auto h-6 w-6" aria-hidden="true" />
                    <span className="block text-center text-xs">{item.name}</span>
                  </Link>
                ))}
              </nav>
            </div>

            <div className="flex-shrink-0 flex p-2">
              <button
                onClick={handleSignout}
                className="flex-shrink-0 items-center w-16 py-4 rounded-lg text-gray-200 hover:text-white hover:bg-gray-700">
                <ArrowRightOnRectangleIcon className="mx-auto h-6 w-6" />
                <span className="block text-center text-xs">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
        {/* Mobile top navigation */}
        <div className="lg:hidden">
          <div className="bg-gray-900  flex items-center justify-between">
            <Link
              to="/"
              className="bg-gray-900 text-gray-200 p-2 my-2 mx-4 sm:px-2  rounded-lg hover:text-white hover:bg-gray-700">
              <Logo className="h-10 w-auto" alt="Fomoro Manufacturing Solutions" />
            </Link>
            <div>
              <button
                type="button"
                className="mx-4 h-12 w-12 inline-flex items-center justify-center bg-gray-800 rounded-md text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setMobileMenuOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars4Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        {(headerTitle || headerActions) && (
          <div className="bg-white py-4 px-4 flex items-center justify-between shadow relative z-10 sm:px-6">
            <Link to="/">
              <h2 className="font-bold text-gray-900 text-3xl sm:truncate">{headerTitle ? headerTitle : ' '}</h2>
            </Link>
            {headerActions && <div className="md:flex-shrink-0 flex">{headerActions}</div>}
          </div>
        )}

        <main className="sm:flex-1 sm:flex overflow-hidden">
          {sectionNav && <SectionNav sectionNav={sectionNav} secondaryNav={secondaryNav} title={sectionTitle} />}
          <div className="flex flex-1 flex-col overflow-y-auto">
            <div className="h-full">{props.children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PrivateTemplate;
