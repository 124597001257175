import { WrenchScrewdriverIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';

export const getReportNav = (reportId, currentSection) => {
  const current = currentSection || 'settings';
  return [
    {
      name: 'Overview',
      to: `/report/${reportId}/overview`,
      icon: ChartBarSquareIcon,
      current: current === 'overview',
    },
    {
      name: 'Settings',
      to: `/report/${reportId}/settings`,
      icon: WrenchScrewdriverIcon,
      current: current === 'settings',
    },
  ];
};

export default getReportNav;
