import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Switch } from '@headlessui/react';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { getJobs, selectJobs, selectJob } from 'state/slices/jobsSlice';
import { activateDeviceJob, updateComponentState } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import Pagination from '../components/Pagination';
import BarcodeForm from '../components/BarcodeForm';
import ModalDialogue from '../components/ModalDialogue';
import SuccessBanner from '../components/SuccessBanner';

import classNames from 'utils/classNames';

export const Ops = () => {
  const { filter, pageNumber } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { method, status, args } = useSelector(state => state.status);

  const [showWarning, setShowWarning] = useState(false);
  const [savedJob, setSavedJob] = useState('');
  const [workOrder, setWorkOrder] = useState('');

  const page = parseInt(pageNumber) || 1;
  const getSelectedJobs = useMemo(selectJobs, []);
  const pageData = useSelector(state => getSelectedJobs(state, { filter, page }));

  const getSelectedJob = useMemo(selectJob, []);
  const { jobName } = useSelector(state => getSelectedJob(state, { jobId: savedJob.jobId }));

  useEffect(() => {
    dispatch(getJobs());
  }, []); // eslint-disable-line

  const filterHandler = e => {
    const filter = encodeURIComponent(e.target.value);
    history.push(`/ops/${page}/${filter}`);
  };

  const activateJobHandler = shouldUpdate => {
    const customerWorkOrder = shouldUpdate ? workOrder : savedJob.customerWorkOrder;
    const deviceId = savedJob.device.deviceId;
    const jobId = savedJob.jobId;
    dispatch(activateDeviceJob({ deviceId, jobId, customerWorkOrder, mode: 'ACTIVE', jobName: savedJob.jobName }));
    setShowWarning(false);
  };

  const toggleJobHandler = (job, isActive) => {
    const deviceId = job.device.deviceId;
    if (!isActive) {
      setSavedJob(job);
      setShowWarning(true);
    } else {
      dispatch(
        updateComponentState({
          deviceId,
          componentName: 'QualityControl',
          state: { mode: 'READY' },
        })
      );
    }
  };

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <ModalDialogue
        title={`Change or Add Work Order to ${jobName}?`}
        confirmHandler={() => activateJobHandler(true)}
        cancelHandler={() => activateJobHandler(false)}
        isOpen={showWarning}>
        <input
          autoFocus
          id="workOrder"
          name="workOrder"
          type="text"
          onChange={e => setWorkOrder(e.target.value)}
          className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
        />
      </ModalDialogue>

      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          className="focus:ring-indigo-700 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
          defaultValue={filter}
          onChange={filterHandler}
        />
      </div>
    </div>
  );

  return (
    <PrivateTemplate headerTitle="Operations" headerActions={headerActions}>
      {method === 'activateDeviceJob' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully activated ${args.jobName}`} />
      )}
      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-scroll border-b border-gray-200">
            <div className="w-full divide-y divide-gray-200 px-6 lg:px-8">
              <BarcodeForm />
            </div>

            <table className="mt-4 w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job Name
                  </th>
                  <th
                    scope="col"
                    className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Station
                  </th>
                  <th
                    scope="col"
                    className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Job (Item) Id
                  </th>
                  <th
                    scope="col"
                    className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Work Order
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {pageData.items.length === 0
                  ? [1, 2, 3, 4, 5].map(index => (
                      <tr key={`skeleton-${index}`} className="animate-pulse">
                        <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                          <Switch.Group as="div" className="flex items-center justify-between">
                            <Switch
                              checked={false}
                              className={
                                'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                              }>
                              <span
                                aria-hidden="true"
                                className={
                                  'translate-x-0pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                }
                              />
                            </Switch>
                          </Switch.Group>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </td>
                      </tr>
                    ))
                  : pageData.items.map(job => (
                      <tr key={job.jobId}>
                        <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                          <Switch.Group as="div" className="flex items-center justify-between">
                            <Switch
                              checked={job.device.activeJobId === job.jobId}
                              onChange={() => toggleJobHandler(job, job.device.activeJobId === job.jobId)}
                              className={classNames(
                                job.device.activeJobId === job.jobId ? 'bg-indigo-800' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  job.device.activeJobId === job.jobId ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="text-sm">{job.jobName}</div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="text-sm">{job.device.deviceName}</div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="text-sm">{job.customerJobId}</div>
                        </td>
                        <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                          <div className="text-sm">{job.customerWorkOrder}</div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>

            <Pagination pageData={pageData} baseUrl={`/jobs/${filter}`} />
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Ops;
